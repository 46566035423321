<template>
<v-container>
    <v-row>
        <v-col align="center">
            <h3>404 error</h3>
            <h4>...redirecting...</h4>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    mounted: function(){
        let self = this
        setTimeout(function(){
            self.$router.push('/')
        },3000)
    }
}
</script>

<style>

</style>